import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthContext";

function ProtectedRoute({ children }) {
  const { user, loading } = useContext(AuthContext);
  const location = useLocation();

  if (loading) {
    const loaderContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
      backgroundColor: '#fff'
    };

    const loaderStyle = {
      width: '50px',
      height: '50px',
      border: '5px solid #ccc',
      borderTop: '5px solid rgb(100, 100, 200)',
      borderRadius: '50%',
      animation: 'spin 1s linear infinite'
    };

    return (
      <>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
        <div style={loaderContainerStyle}>
          <div style={loaderStyle} />
        </div>
      </>
    );
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

export default ProtectedRoute;