import { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import styles from './Home.module.css';

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/";

  // For server-sive login
  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch("https://neolesson.su/api/auth/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      const { token } = await response.json();
      login(token);
      navigate(from); // Redirect to the originally requested page or home
    } else {
      alert("Invalid credentials");
    }
  };

  // For local login
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Mock credentials
  //   const mockUser = { username: "admin", password: "admin" };

  //   if (username === mockUser.username && password === mockUser.password) {
  //     const token = "mock-token"; // Mock token
  //     login(token); // Simulate successful login
  //     navigate(from); // Redirect to the requested page
  //   } else {
  //     alert("Invalid credentials");
  //   }
  // };

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '25%',
          fontSize: '1.5rem',
          color: 'rgb(0,0,0)',
          fontFamily: "Montserrat",
          fontStyle: 'normal',
          fontWeight: "600"
        }}
      >
      <h1 style={{ margin: '10px 0', textAlign: 'center', fontFamily: "Montserrat", fontWeight: "600" }}>Вход <br/> в Новый Урок</h1>
      <input
        style={{ margin: '10px 0', padding: '10px', width: '100%' }}
        type="text"
        placeholder="логин или почта"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        style={{ margin: '10px 0', padding: '10px', width: '100%' }}
        type="password"
        placeholder="пароль"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
        <button style={{ margin: '10px 0', padding: '10px 20px', fontSize: '1.5rem',  fontFamily: "Montserrat", fontWeight: "700" }} type="submit">
          Войти
        </button>
      </div>
    </form>
  );
}

export default LoginPage;