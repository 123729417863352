import React, { createContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Simulate async localStorage retrieval
    const savedToken = localStorage.getItem("authToken");
    if (savedToken) {
      setUser({ token: savedToken });
    }
    setLoading(false); // We have checked localStorage
  }, []);

  const login = (token) => {
    setUser({ token });
    localStorage.setItem("authToken", token);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("authToken");
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;