import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css'; // Import the CSS styles

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-center">
        <NavLink exact="true" to="/" className="nav-link" activeclassname="active">
          Новый Урок
        </NavLink>
        <NavLink to="/lessons" className="nav-link" activeclassname="active">
          Материалы
        </NavLink>
        <NavLink to="/about" className="nav-link" activeclassname="active">
          О нас
        </NavLink>
        <NavLink to="/user-cabinet" className="nav-link" activeclassname="active">
          Личный кабинет
        </NavLink>
        <NavLink to="/login" className="nav-link" activeclassname="active">
          Логин
        </NavLink>
      </div>
    </nav>
  );
}

export default Navbar;