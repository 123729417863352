import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import ErrorBoundary from './components/ErrorBoundary';
import { AuthProvider } from "./context/AuthContext";
import AboutUs from './pages/AboutUs';
import LoginPage from './pages/LoginPage';
import ProtectedRoute from "./pages/ProtectedRouter";
// Lazy-loaded components
const Home = React.lazy(() => import('./pages/Home'));
const Lessons = React.lazy(() => import('./pages/Lessons'));
const LessonPage = React.lazy(() => import('./pages/LessonPage'));
const UserCabinet = React.lazy(() => import('./pages/UserCabinet'));


function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <Navbar />
          <ErrorBoundary>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/lessons"
                  element={
                    <ProtectedRoute>
                      <Lessons />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-cabinet"
                  element={
                    <ProtectedRoute>
                      <UserCabinet />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/lessons/:id"
                  element={
                    <ProtectedRoute>
                      <LessonPage />
                    </ProtectedRoute>
                  }
                />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/login" element={<LoginPage />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;